<template>
  <login-layout>
    <div v-if="registration">
      <div class="row">
        <h1 class="loginPage__title">Registration</h1>
      </div>
      <div class="row">
        <div class="col-lg-6 loginPage__createNewAccount">
          <!--          Already have an account?-->
          <!--          <router-link class="loginPage__link" to="/login">Log In.</router-link>-->
          <!--          .-->
        </div>
      </div>
      <div class="row registrationPage__stepContainer">
        <div class="col-6 registrationPage__stepTitle">
          <span v-if="steps.main"> Main User information </span>
          <span v-if="steps.contact"> Contact information </span>
          <span v-if="steps.payment"> Payment method </span>
        </div>
        <div class="row">
          <div class="registrationPage__steps">
            <div
              v-for="(step, key, number) in steps"
              class="registrationPage__step"
              :class="{ 'registrationPage__step-active': viewStep >= number }"
            ></div>
          </div>
        </div>
      </div>
      <div v-if="steps.main">
        <div class="registrationPage__stepSubtitle">
          Please enter your credentials to proceed.
        </div>
        <div class="row col-lg-8">
          <div class="col-6 pr-25">
            <base-input
              class="registrationPage__input w-100"
              :error="v$.employee.first_name.$error"
              :error-message="v$.employee.first_name.$errors"
              v-model="employee.first_name"
              >Enter your name*
            </base-input>
            <base-input
              :read-only="true"
              :autocomplete="true"
              class="registrationPage__input w-100"
              v-model="employee.email"
              >Enter your email*
            </base-input>
            <div
              class="position-relative accountPage__input registrationPage__input mb-0"
            >
              <span
                :class="{ 'baseInput__area-focus': employee.language }"
                class="baseInput__label"
              >
                Select prefered language*
              </span>
              <vue-multiselect
                class="accountOverviewMultiselect"
                placeholder=""
                :show-labels="false"
                open-direction="bottom"
                label="name"
                track-by="name"
                v-model="employee.language"
                :options="languages"
              />
            </div>
            <div class="position-relative">
              <base-input
                class="passwordResetPopup__input registrationPage__input w-100"
                v-model="password"
                :error="v$.password.$error"
                :error-message="v$.password.$errors"
                :maxlength="32"
                :type="passwordInputType"
                >Create password*
              </base-input>
              <div
                v-if="password && password.length > 0"
                class="registrationPage__passwordIcon"
                @click="showPassword = !showPassword"
              >
                <show-password-icon />
              </div>
              <base-button
                v-if="password.length === 0"
                class="buttons__passwordGenerate"
                @click="passwordGenerate()"
              >
                Generate
              </base-button>
            </div>
          </div>
          <div class="col-6 pl-25">
            <base-input
              class="registrationPage__input w-100"
              :error="v$.employee.last_name.$error"
              :error-message="v$.employee.last_name.$errors"
              v-model="employee.last_name"
              >Enter your surname*
            </base-input>
            <div class="d-flex justify-content-between registrationPage__input">
              <div
                class="position-relative accountPage__input accountPage__input-small mb-0"
              >
                <span
                  :class="{ 'baseInput__area-focus': employee.gender }"
                  class="baseInput__label"
                >
                  Gender
                </span>
                <vue-multiselect
                  class="accountOverviewMultiselect"
                  :options="genders"
                  v-model="employee.gender"
                  :show-labels="false"
                  open-direction="bottom"
                  placeholder=""
                  label="name"
                  track-by="name"
                />
              </div>
              <div class="position-relative accountPage__input-small mb-0">
                <span
                  class="baseInput__label"
                  :class="{ 'baseInput__area-focus': employee.birthdate }"
                  >Date of birth</span
                >
                <datepicker
                  inputClassName="registrationPage__datePicker"
                  class=""
                  v-model="employee.birthdate"
                  format="dd/MM/yyyy"
                  :flow="flow"
                  hideInputIcon
                  autoApply
                />
              </div>
            </div>
            <div
              class="position-relative accountPage__input registrationPage__input mb-0"
            >
              <span
                :class="{ 'baseInput__area-focus': employee.position }"
                class="baseInput__label"
              >
                Select your position
              </span>
              <vue-multiselect
                class="accountOverviewMultiselect"
                placeholder=""
                v-model="employee.position"
                :show-labels="false"
                open-direction="bottom"
                :custom-label="customLabel"
                :options="positions"
              >
                <template v-slot:option="{ option }">
                  {{ option.translations[0].name }}
                </template>
              </vue-multiselect>
            </div>
            <div class="position-relative">
              <base-input
                class="registrationPage__input w-100"
                :error="v$.repeatPassword.$error"
                :error-message="v$.repeatPassword.$errors"
                :maxlength="32"
                :type="passwordInputType"
                v-model="repeatPassword"
                >Repeat password*
              </base-input>
              <div
                v-if="repeatPassword.length > 0"
                class="registrationPage__passwordIcon"
                @click="showPassword = !showPassword"
              >
                <show-password-icon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="steps.contact">
        <div class="registrationPage__stepSubtitle">
          Please enter your contact information.
        </div>
        <div class="registrationPage__stepScip">
          You can skip this step now and fill that information later in your
          Personal Cabinet after registration.
        </div>
        <div class="row col-lg-8">
          <div class="col-6 pr-25">
            <base-input
              class="registrationPage__input w-100"
              v-model="employee.country"
              >Country
            </base-input>
            <base-input
              class="registrationPage__input w-100"
              v-model="employee.address"
              >Address
            </base-input>
            <base-input
              class="registrationPage__input w-100"
              v-model="employee.mobile_number"
              >Mobile number
            </base-input>
          </div>
          <div class="col-6 pl-25">
            <div class="d-flex justify-content-between registrationPage__input">
              <div class="accountPage__input accountPage__input-small mb-0">
                <base-input class="w-100" v-model="employee.city"
                  >City
                </base-input>
              </div>
              <div class="accountPage__input accountPage__input-small mb-0">
                <base-input class="w-100" v-model="employee.postal_code"
                  >Postal code
                </base-input>
              </div>
            </div>
            <base-input
              class="registrationPage__input w-100"
              v-model="employee.office_number"
              >Office number
            </base-input>
            <base-input
              class="registrationPage__input w-100"
              v-model="employee.phone_number"
              >Phone number
            </base-input>
          </div>
        </div>
      </div>
      <div v-if="steps.payment">
        <div class="registrationPage__stepSubtitle">
          Please enter your billing information.
        </div>
        <div class="registrationPage__stepScip">
          These data will be used to purchase appointments with experts on the
          platform. You can skip this step now and fill that information later
          in your Personal Cabinet.
        </div>
        <div class="row col-lg-8">
          <div class="col-6">
            <div class="registrationPage__card">
              <base-input
                class="w-100 registrationPage__cardInput"
                mask="####-####-####-####"
                v-model="employee.card.number"
                >Card number
              </base-input>
              <div
                class="d-flex justify-content-between registrationPage__input"
              >
                <div
                  class="accountPage__input-small registrationPage__cardInput"
                >
                  <base-input class="w-100" mask="##/####" v-model="cartDate">
                    Expire date
                  </base-input>
                </div>
                <div
                  class="accountPage__input-small registrationPage__cardInput"
                >
                  <base-input
                    class="w-100"
                    mask="####"
                    v-model="employee.card.cvc"
                  >
                    CVC/CVV
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 mt-5">
        <div class="d-flex align-items-center justify-content-between">
          <base-button
            class="w-max-content"
            :calendar="true"
            @click="nextStep()"
            >Continue
          </base-button>
          <div
            v-if="viewStep === 1"
            class="accountPage__skip"
            @click="skipStep('contact')"
          >
            Add contact information later >
          </div>
          <div
            v-if="viewStep === 2"
            class="accountPage__skip"
            @click="skipStep('card')"
          >
            Add payment method later >
          </div>
        </div>
        <div
          class="accountPage__back"
          v-if="viewStep !== 0"
          @click="backStep()"
        >
          &lt; Back
        </div>
      </div>
    </div>
    <div v-if="confirmation">
      <h1 class="loginPage__title">Account confirmation</h1>
      <div class="registrationPage__confirmationWrapper">
        <div class="d-flex align-items-center mb-10">
          <confirmation-icon class="me-10" />
          We have sent you an email at &nbsp;
          <span class="blue"> {{ employee.email }}</span>
        </div>
        <div class="loginPage__mb-50">
          Please, check out your inbox for an email from Aura Impact Platform
          and follow the link to confirm your account.
        </div>
        <div class="mb-10">Didn’t receive a message?</div>
        <ul class="registrationPage__confirmationList">
          <li>- Check the correctness of the entered email</li>
          <li>- Check your mailbox spam</li>
          <li>- Click Resend email.</li>
        </ul>
      </div>
      <base-button v-if="resendMailState" @click="resendMail()" :calendar="true"
        >Resend email
      </base-button>
    </div>
  </login-layout>
</template>

<script>
import LoginLayout from "@/layouts/LoginLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";
import BaseInput from "@/components/UI/inputs/BaseInput";
import VueMultiselect from "vue-multiselect";
import ConfirmationIcon from "@/components/UI/icons/buttonIcons/ConfirmationIcon.vue";
import ShowPasswordIcon from "@/components/UI/icons/ShowPasswordIcon";
import Datepicker from "@vuepic/vue-datepicker";
import { ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { useToast } from "vue-toastification";
import { minLength, required, sameAs } from "@vuelidate/validators";
import { format } from "date-fns";

export default {
  name: "RegistrationPage",
  components: {
    ShowPasswordIcon,
    ConfirmationIcon,
    BaseInput,
    BaseButton,
    LoginLayout,
    VueMultiselect,
    Datepicker,
  },
  setup() {
    const flow = ref(["year", "month", "calendar"]);
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      flow,
      toast,
      v$,
    };
  },
  data() {
    return {
      showPassword: false,
      steps: {
        main: true,
        contact: false,
        payment: false,
      },
      registration: true,
      confirmation: false,
      cartDate: "",
      viewStep: 0,
      languages: [],
      genders: [],
      positions: [],
      employee: {
        gender: null,
        gender_id: null,
        position: null,
        position_id: null,
        language: null,
        email: null,
        first_name: null,
        last_name: null,
        country: null,
        city: null,
        postal_code: null,
        address: null,
        mobile_number: null,
        phone_number: null,
        office_number: null,
        password: null,
        repeatPassword: null,
        birthdate: null,
        image: null,
        profile: {
          language_id: null,
        },
        user: {
          language_id: null,
          email: null,
          password: null,
        },
        card: {
          skip: false,
          exp_month: null,
          exp_year: null,
          number: null,
          cvc: null,
        },
      },
      password: "",
      repeatPassword: "",
      validate: {
        first_name: { required },
        last_name: { required },
      },
      resendMailState: true,
      registrationCode: null,
    };
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(8),
        sameAs: sameAs(this.repeatPassword),
      },
      repeatPassword: {
        required,
        minLength: minLength(8),
        sameAs: sameAs(this.password),
      },
      employee: this.validate,
    };
  },
  mounted() {
    this.$noAuth
      .get(`/users/code/${this.$route.query.code}`)
      .then(({ data }) => {
        this.employee.email = data.body.email;
        this.registrationCode = this.$route.query.code;
        this.getOptions();
      })
      .catch(() => {
        this.$router.push("/login");
      });
  },
  methods: {
    customLabel({ translations }) {
      return `${translations[0].name}`;
    },
    passwordGenerate() {
      this.password = Math.floor(Math.random() * 1000000000000000000).toString(
        36
      );
      this.repeatPassword = this.password;
    },
    getOptions() {
      this.$noAuth.get("/genders", {}).then(({ data }) => {
        this.genders = data.body;
      });
      this.$noAuth.get("/platform-languages", {}).then(({ data }) => {
        this.languages = data.body;
        this.employee.language = data.body[1];
      });
      this.$noAuth.get("/employees-positions", {}).then(({ data }) => {
        this.positions = data.body;
      });
    },
    resendMail() {
      this.$noAuth
        .get("/users/email/" + this.employee.email + "/activation")
        .then(() => {
          this.resendMailState = false;
          this.toast.success(this.$t("emailSuccessesSend"));
        });
    },
    async nextStep() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      let keys = Object.keys(this.steps);
      if (this.viewStep < 2) {
        for (let i in this.steps) {
          this.steps[i] = false;
        }
        this.viewStep++;
        this.steps[keys[this.viewStep]] = true;
      } else {
        this.registrationUser();
      }
    },
    backStep() {
      let keys = Object.keys(this.steps);

      if (this.viewStep !== 0) {
        for (let i in this.steps) {
          this.steps[i] = false;
        }
        this.viewStep--;
        if (keys[this.viewStep] === "main") {
          this.validate = {
            first_name: { required },
            last_name: { required },
          };
        }
        this.steps[keys[this.viewStep]] = true;
      }
    },
    skipStep(step) {
      if (step === "card") {
        this.employee.card.skip = true;
        this.registrationUser();
      } else {
        this.employee.country = "";
        this.employee.city = "";
        this.employee.postal_code = "";
        this.employee.address = "";
        this.employee.office_number = "";
        this.employee.mobile_number = "";
        this.employee.phone_number = "";
        this.nextStep();
      }
    },
    registrationUser() {
      let cardValue = this.employee.card.skip
        ? null
        : {
            exp_month: +this.cartDate.slice(0, 2),
            exp_year: +this.cartDate.slice(3),
          };
      let birthday = this.employee.birthdate
        ? format(new Date(this.employee.birthdate), "yyyy-MM-dd") +
          "T00:00:00.000Z"
        : null;
      this.$noAuth
        .post("/auth/register", {
          registration_code: this.registrationCode,
          language_id: this.employee.language
            ? this.employee.language.id
            : null,
          password: this.password,
          employee: {
            gender_id: this.employee.gender ? this.employee.gender.id : null,
            position_id: this.employee.position
              ? this.employee.position.id
              : null,
            first_name: this.employee.first_name,
            last_name: this.employee.last_name,
            country: this.employee.country,
            city: this.employee.city,
            postal_code: this.employee.postal_code,
            address: this.employee.address,
            mobile_number: this.employee.mobile_number,
            phone_number: this.employee.phone_number,
            office_number: this.employee.office_number,
            birthdate: birthday,
          },
          card: cardValue,
        })
        .then(() => {
          this.toast.success(this.$t("registrationCompleted"));
          this.$router.push("/login");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    async checkMail() {
      return this.$noAuth
        .get("/users/email/" + this.employee.email + "/exist")
        .then(({ data }) => {
          return data.body;
        });
    },
  },
  computed: {
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    },
  },
};
</script>

<style></style>
